<template>
    <section class="pt-5 pb-5 realestate-home-pd-img">
        <div class=".container px-3 text-center">
            <h2 class="mb-3">สินค้าของเราสั่งเลย</h2>
            <div class="row justify-content-md-center">
                <div class="col-md-2 col-6 mb-md-0 mb-4" v-for="item in viewShow">
                    <router-link class="" to="/show">
                        <img class="rounded" :src="item.img" alt="">
                        <h6 class="mb-0 mt-3">{{ item.name }}</h6>
                    </router-link>
                </div>
                <!-- <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <router-link class="" to="/show">
                        <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/product/product-1.png" alt="">
                        <h6 class="mb-0 mt-3">ชื่อสินค้า *****</h6>
                    </router-link>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <router-link class="" to="/show">
                        <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/product/product-1.png" alt="">
                        <h6 class="mb-0 mt-3">ชื่อสินค้า *****</h6>
                    </router-link>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <router-link class="" to="/show">
                        <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/product/product-1.png" alt="">
                        <h6 class="mb-0 mt-3">ชื่อสินค้า *****</h6>
                    </router-link>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <router-link class="" to="/show">
                        <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/product/product-1.png" alt="">
                        <h6 class="mb-0 mt-3">ชื่อสินค้า *****</h6>
                    </router-link>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <router-link class="" to="/show">
                        <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/product/product-1.png" alt="">
                        <h6 class="mb-0 mt-3">ชื่อสินค้า *****</h6>
                    </router-link>
                </div> -->
            </div>
            <router-link class="btn btn-primary mt-4" to="/show">
                <i class="mdi fs-16 mdi-cart me-1"></i>
                ดูสินค้า สินค้าทั้งหมด
            </router-link>
        </div>
    </section>
</template>
 
<script lang="js">
    // Options
    import { Options, Vue } from "vue-class-component";
    // Options
    @Options({
        data() {
            return {
                viewShow: [
                    {
                        'name': 'รสออริจินอล',
                        'price': '50',
                        'img': '/layouts/templates/roofing_info/assets/uploads/page/show/item/originalกลาง.jpg?vl=001',
                    },
                    // {
                    //     'name': 'รสออริจินอล ใหญ่',
                    //     'price': '100',
                    //     'img': '/layouts/templates/roofing_info/assets/uploads/page/show/item/originalใหญ่.jpg?vl=001',
                    // },
                    {
                        'name': 'รสลิ้นจี่',
                        'price': '50',
                        'img': '/layouts/templates/roofing_info/assets/uploads/page/show/item/ลิ้นจี่กลาง.jpg?vl=001',
                    },
                    // {
                    //     'name': 'รสลิ้นจี่ ใหญ่',
                    //     'price': '100',
                    //     'img': '/layouts/templates/roofing_info/assets/uploads/page/show/item/ลิ้นจี่ใหญ่.jpg?vl=001',
                    // },
                    {
                        'name': 'รสสตอเบอรี่',
                        'price': '50',
                        'img': '/layouts/templates/roofing_info/assets/uploads/page/show/item/สตอเบอรี่กลาง.jpg?vl=001',
                    },
                    // {
                    //     'name': 'รสสตอเบอรี่ ใหญ่',
                    //     'price': '100',
                    //     'img': '/layouts/templates/roofing_info/assets/uploads/page/show/item/สตอเบอรี่ใหญ่.jpg?vl=001',
                    // },
                    {
                        'name': 'รสองุ่น',
                        'price': '50',
                        'img': '/layouts/templates/roofing_info/assets/uploads/page/show/item/องุ่นกลาง.jpg?vl=001',
                    },
                    // {
                    //     'name': 'รสองุ่น ใหญ่',
                    //     'price': '100',
                    //     'img': '/layouts/templates/roofing_info/assets/uploads/page/show/item/องุ่นใหญ่.jpg?vl=001',
                    // },
                    {
                        'name': 'รสแอปเปิ้ลเขียว',
                        'price': '50',
                        'img': '/layouts/templates/roofing_info/assets/uploads/page/show/item/แอปเปิ้ลเขียวกลาง.jpg?vl=001',
                    },
                    // {
                    //     'name': 'รสแอปเปิ้ลเขียว ใหญ่',
                    //     'price': '100',
                    //     'img': '/layouts/templates/roofing_info/assets/uploads/page/show/item/แอปเปิ้ลเขียวใหญ่.jpg?vl=001',
                    // },
                ],
            };
        },
    })
    export default class Home_view extends Vue { }
</script>

<style>
    .realestate-home-pd-img {
    /* background-image: linear-gradient(rgb(194 228 242 / 0%), rgb(206 240 255 / 0%)), url(https:/kratom.com/wp-content/uploads/2023/07/BG-WEB.webp); */
    /* padding: 60px 140px 94px; */
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    /* height: 200px; */
    background-color: #f6931e14;
  }
</style>
 
