

<template>
    <header class="navbar navbar-expand-lg fixed-top bg-light">
        <div class="container">
            <router-link class="navbar-brand pe-sm-3" to="/">
                <a>
                    <img src="/layouts/templates/roofing_info/assets/uploads/logo/logo.png" width="45" alt="">
                </a>
            </router-link>
            <div class="d-flex align-items-center order-lg-2 ms-auto">
                <a v-bind:href="'tel:' + envTelOne " class="d-flex align-items-center text-decoration-none">
                    <i class="ai-phone fs-3"></i>
                    <div class="text-nowrap ps-2 d-none d-lg-block">
                    <h6 class="fs-sm lh-1 mb-0">สายด่วน 24 ชม.</h6>
                    <span class="text-body fs-xs">(+66) {{envTelOne}}</span>
                    </div>
                </a>
                <a v-bind:href=" envLineURL " class="d-flex align-items-center text-decoration-none ms-4">
                    <i class="ai-messages fs-3"></i>
                        <div class="text-nowrap ps-2 d-none d-lg-block">
                        <h6 class="fs-sm lh-1 mb-0">Line ID</h6>
                        <span class="text-body fs-xs">ID's Line : {{envLineID}}</span>
                    </div>
                </a>
            </div>
            <button class="navbar-toggler ms-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
                <span class="navbar-toggler-icon">
                </span>
            </button>
            <nav class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav navbar-nav-scroll me-auto" style="--ar-scroll-height: 520px;">
                    <li class="nav-item">
                        <router-link class="nav-link .active" to="/">
                            <a >หน้าแรก</a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/show">
                            <a>สินค้าของเรา</a>
                        </router-link>
                    </li>
                    <li class="nav-item d-none d-md-block">
                        <a class="nav-link" :href="envLineURL">
                            <a>Line official account  เบาเบา</a>
                        </a>
                    </li>
                    <li class="nav-item d-none d-md-block">
                        <a class="nav-link" :href="envFacebookURL">
                            <a>Facebook เบาเบา</a>
                        </a>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/contact">
                            <a>ติดต่อเรา และ สอบถาม</a>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
    data () {
        return {
            envTelOne: process.env.VUE_APP_CT_TEL_ONE,
            envLineName: process.env.VUE_APP_ST_LINE_NAME,
            envLineID: process.env.VUE_APP_ST_LINE_ID,
            envLineURL: process.env.VUE_APP_ST_LINE_URL,
            //
            envFacebookName: process.env.VUE_APP_ST_FACEBOOK_NAME,
            envFacebookURL: process.env.VUE_APP_ST_FACEBOOK_URL,
        }
    },
    // created
    created() {
        // into
        function into() {

        }
        setTimeout(function () {
            into();
        }, 150);
    },
})
export default class HeaderPageOne extends Vue { }

</script>

<style scoped lang="scss">
    ul .nav-link {
        font-weight: 600 !important;
        color: #fff;
        font-size: 18px;
    }
    ul .nav-link:hover, ul .nav-link:focus , ul .nav-link:active {
        color: #3e3e3e;
    }
    header h6 {
        color: #fff !important;
    }
    header span {
        color: #fff !important;
    }
    header i {
        color: #fff !important;
    }
    header {
        background-color: #f6931e !important;
    }
    .navbar-expand-lg .navbar-nav {
        padding: revert;
    }
    .navbar-toggler-icon, .navbar-toggler-icon::before, .navbar-toggler-icon::after {
        position: absolute;
        width: 1.375rem;
        height: 0.125rem;
        transition-property: transform;
        background-color: #fff;
    }
    .navbar .navbar-collapse {
        border-color: var(--ar-navbar-collapse-border-color);
        background-color: #f6931e;
    }
 
</style>
  
