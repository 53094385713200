<template>
    <section class="pt-5 pb-5">
        <div class="container text-center">
            <h2 class="mb-4">สินค้าของเราสั่งเลย</h2>
            <div class="row justify-content-md-center">
                <div class="col-md-3 mb-md-0 mb-4">
                    <img class="img-r-50" src="/layouts/templates/roofing_info/assets/uploads/page/home/product_group/group-1.png" alt="">
                    <div class="mt-3">
                        <h5 class="mb-2">Baobao</h5>
                        <router-link class="btn btn-primary" to="/show">
                            <i class="mdi fs-16 mdi-cart me-1"></i>
                            ดูสินค้า Baobao
                        </router-link>
                    </div>
                </div>
                <div class="col-md-3 mb-md-0 mb-4">
                    <img class="img-r-50" src="/layouts/templates/roofing_info/assets/uploads/page/home/product_group/group-1.png" alt="">
                    <div class="mt-3">
                        <h5 class="mb-2">CRAFT SODA</h5>
                        <router-link class="btn btn-primary" to="/show">
                            <i class="mdi fs-16 mdi-cart me-1"></i>
                            ดูสินค้า CRAFT SODA
                        </router-link>
                  
                    </div>
                </div>
                <div class="col-md-3 mb-md-0 mb-4">
                    <img class="img-r-50" src="/layouts/templates/roofing_info/assets/uploads/page/home/product_group/group-1.png" alt="">
                    <div class="mt-3">
                        <h5 class="mb-2">CRAFT SODA</h5>
                        <router-link class="btn btn-primary" to="/show">
                            <i class="mdi fs-16 mdi-cart me-1"></i>
                            ดูสินค้า CRAFT SODA
                        </router-link>
                  
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
 
<script lang="js">
    // Options
    import { Options, Vue } from "vue-class-component";
    // Options
    @Options({
        data() {
            return {};
        },
    })
    export default class Home_view extends Vue { }
</script>

<style>
    .img-r-50 {
        border-radius: 50% !important;
    }
</style>
 
