<template>
  <section class=" margin-info-top">
    <div>
      <img
        src="/layouts/templates/roofing_info/assets/uploads/page/home/info/info-top-1.png"
        alt="">
    </div>
  </section>
</template>
  
<script lang="js">
  // Options
  import { Options, Vue } from "vue-class-component";
  // Options
  @Options({
    data() {
      return {};
    },
  })
  export default class Home_view extends Vue { }
</script>
  
<style>
  .margin-info-top {
    margin-top: 74px;
  }
</style>