<template>
    <section class="map-pb-e-10">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3869.966263095379!2d100.6281674!3d14.079168899999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d81a3d7a4af41%3A0x77395e69b63a91a7!2sbaobao!5e0!3m2!1sth!2sth!4v1699243312413!5m2!1sth!2sth" width="100%" height="380" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>
</template>
  
<script lang="js">
    // Options
    import { Options, Vue } from "vue-class-component";
    // Options
    @Options({
        data() {
            return {};
        },
    })
    export default class Contact_view extends Vue { }
</script>

<style>
    .map-pb-e-10 iframe {
        margin-bottom: -10px;
    }
</style>