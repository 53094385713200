<template>
    <section class="pt-5 pb-5 realestate-home-at-img" style="background-image: linear-gradient(rgb(194 228 242 / 0%), rgb(206 240 255 / 0%)), url('/layouts/templates/roofing_info/assets/uploads/bg/info-bg.png');">
        <div class="container text-center">
            <div class="mb-3">
                <h2 class="mb-0">กิจกรรม เบาเบา สมัครเป็นชาว เบาเบา</h2>
                <p class="mb-4">รับทันที 20 Point สะสมพ้อยแลกของรางวัล ดูของรางวัลได้ที่หน้า Baobao เลอ</p>
            </div>
            <img class="mb-3 rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/activity/activity-1.png" alt="">
            <div class="row ">
                <div class="col-md-4 col-12 mb-md-4 mb-4">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/activity/activiy-info-1.png" alt="">
                </div>
                <div class="col-md-4 col-12 mb-md-4 mb-4">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/activity/activiy-info-2.png" alt="">
                </div>
                <div class="col-md-4 col-12 mb-md-4 mb-4">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/activity/activiy-info-3.png" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">
                    <h3 class="mb-0">เบาเบา</h3>
                    <p>
                        เบาเบา หรือ เบาเบา คือเครื่องดื่มสมุนไพรไทยที่อยู่คู่ท้องถิ่นมานาน เบาเบาเรามองเห็นถึงสรรพคุณของพืชกระท่อมว่ามีประโยชน์มาก และ สามารถต่อยอดเป็นยาได้หลายแขนง
                        ” ประเทศไทย ” ถือว่าได้เปรียบเป็นอย่างมากในการเพาะปลูก ” กระท่อม ” จึงทำให้มีสาร ” ไมทราไจนีน ” สมบูรณ์ที่สุด
                        เราขอเป็นส่วนหนึ่งที่ช่วยผลักดันกระท่อมไทยให้เป็นพืชเศรษฐกิจโลก เป็นรายได้ให้พี่น้อง เกษตรกรไทย
                    </p>
                </div>
                <div class="col-12 mb-3">
                    <b class="mb-0">เบาเบา : กระท่อมไทยต้องดังไกลระดับโลก</b>
                    <br>
                    <p>
                        เครื่องดื่มสมุนไพรชนิดนี้คือ น้ำกระท่อม ดื่มเป็นน้ำสมุนไพรบำรุงร่างกาย ในปัจจุบัน น้ำกระท่อม สรรพคุณ หลากหลายขึ้นจากการวิจัยทางวิทยาศาสตร์
                        มีประโยชน์ เช่น ช่วยให้ตื่นตัว ลดอาการปวดเมื่อย – ไอ หรือ นอนไม่หลับ และควรดื่มในปริมาณที่พอเหมาะไม่มากเกินไปซึ่งอาจทำให้มีผลข้างเคียง เช่น อาเจียน วิงเวียนศีรษะ
                        พวกเราชาว เบาเบา เห็นข้อดีเรื่องนี้จึงต้องการเปลี่ยนภาพลักษณ์น้ำกระท่อมให้เป็นเครื่องดื่มสมุนไพรทั่วไปและ น้ำ กระท่อม ใกล้ ฉัน หาดื่มได้ง่ายสะดวกสบายมีแบบ หน้าร้าน และ เดลิเวอรี่
                        เราขอผลักดันกระท่อมไทยให้เป็นพืชเศรษฐกิจที่ผู้คนยอมรับมากขึ้น…
                    </p>
                    <b>🌳 น้ำกระท่อมเบาเบา ดื่มวันจันทร์ยันถึงวันอาทิตย์ 🌳</b>
                </div>
                <div class="col-md-12 mb-3">
                    <img class="mb-3 rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/activity/activity-2.png" alt="">
                </div>
                <div class="col-md-4 mb-3">
                    <h4 class="mb-2 text-color-one">ประโยชน์ของพืชกระท่อม</h4>
                    <p>
                        การ ต้ม น้ำกระท่อม ประโยชน์ ของการดื่มน้ำกระท่อมมีอยู่มากจากการวิจัยต่างๆจากผู้เชี่ยวชาญด้านสมุนไพร และนักวิทยาศาสตร์ได้มีการวิเคราะห์ต้นกระท่อม ประโยชน์ น้ำกระท่อม สามารถช่วยในเรื่องของ การบรรเทาอาการปวด บรรเทาอาการไอ ช่วยลดการหลั่งกรดในกระเพาะ และยังสามารถ ใช้เพื่อให้นอนหลับง่ายขึ้น ทำให้มีสมาธิมากขึ้น พืชกระท่อมยังสามารถใช้ระงับอาการปวดข้อดีของ ผล ข้าง เคียง การ ดื่ม น้ำกระท่อม จะกดอาการหายใจของเรา หรือ ทำให้เกิดการคลื่นไส้อาเจียนน้อยลง
                    </p>
                </div>
                <div class="col-md-4 mb-3">
                    <h4 class="mb-2 text-color-one">โทษของพืชกระท่อม</h4>
                    <p>
                        การ ต้ม น้ำกระท่อม โทษ คือการที่ใช้เกินความจำเป็นใช้มากเกินความเหมาะสมหรือที่หมอกำหนดไว้ จะมี โทษ ของ น้ำกระท่อม อย่างเช่น ทำให้สีผิวคล้ำขึ้นผิวเหลืองขึ้น ทำให้ไม่อยากอาหาร น้ำกระท่อม อาการ ต่างๆที่มีการหาข้อมูลคือ อาการท้องผูก เหงื่อออกบ้างบางทีและปวดปัสสาวะบ่อย ในบางรายอาจทำให้มีอาการแพ้แสง สามารถหาดื่มได้แบบ น้ำกระท่อม สํา เร็ จ รูป เป็นต้น
                    </p>
                </div>
                <div class="col-md-4 mb-3">
                    <h4 class="mb-2 text-color-one">การดื่มน้ำกระท่อม</h4>
                    <p>
                        ในปัจจุบันโลกออนไลน์ได้พัฒนาและยังมีร้านค้าสะดวกซื้อมากมายง่ายเหมือน น้ำกระท่อม 7-11 หากคุณเป็นคนที่สนใจใน ขาย น้ํา กระท่อม ใกล้ ฉัน เดี๋ยวนี้สามารถหาได้ไม่ยาก ขาย น้ํา กระท่อม ใกล้ ฉัน ตามหมู่บ้านที่ใช้ใบกระท่อมอยู่ก่อนแล้วเปลี่ยนจากการขายใบกระท่อมเป็น ขาย น้ำ กระท่อม แทนเพื่อสะดวกต่อการพกพา และใช้ดื่มหาซื้อได้สะดวกเหมือนน้ำกระท่อมจาก น้ำกระท่อม 7 11 หรือในบางพื้นมีการ ขาย น้ำ กระท่อม ใกล้ ฉัน ในบางพื้นที่
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
 
<script lang="js">
    // Options
    import { Options, Vue } from "vue-class-component";
    // Options
    @Options({
        data() {
            return {};
        },
    })
    export default class Home_view extends Vue { }
</script>

<style>
    .realestate-home-at-img {
        /* background-image: linear-gradient(rgb(194 228 242 / 0%), rgb(206 240 255 / 0%)), url(https://Baobaokratom.com/wp-content/uploads/2023/07/BG-WEB.webp); */
        /* padding: 60px 140px 94px; */
        background-size: 100%;
        background-size: cover;
        /* background-repeat: no-repeat; */
        background-position: center center;
        /* height: 200px; */
    }
</style>
 
