<template>
    <div>
      <FullPageLoader></FullPageLoader>
      <ModalPageOne></ModalPageOne>
      <main class="page-wrapper">
        <ModalPageTwo></ModalPageTwo>
        <HeaderPageOne></HeaderPageOne>
        <MobileMenuPageOne></MobileMenuPageOne>
        <div>
          <InfoView></InfoView>
          <!-- <ProductGroupView></ProductGroupView> -->
          <ProductView></ProductView>
          <!-- <SupportView></SupportView> -->
          <ActivityView></ActivityView>
          <QrcodeView></QrcodeView>
          <MapView></MapView>
        </div>
      </main>
      <FooterPageOne></FooterPageOne>
    </div>
  </template>
  
  <script lang="js">
    import FullPageLoader from '@/components/layouts/templates/roofing_info/FullPageLoader.vue'; 
    import HeaderPageOne from '@/components/layouts/templates/roofing_info/HeaderPageOne.vue'; 
    // import MobileMenuPageOne from '@/components/layouts/templates/roofing_info/MobileMenuPageOne.vue'; 
    import FooterPageOne from '@/components/layouts/templates/roofing_info/FooterPageOne.vue'; 
    import ModalPageOne from '@/components/layouts/templates/roofing_info/ModalPageOne.vue'; 
    import ModalPageTwo from '@/components/layouts/templates/roofing_info/ModalPageTwo.vue'; 
    // view
    import InfoView from '../home/view/contact/Info_view.vue'; 
    import ProductGroupView from '../home/view/contact/Product_group_view.vue'; 
    import ProductView from '../home/view/contact/Product_view.vue'; 
    import SupportView from '../home/view/contact/Support_view.vue'; 
    import ActivityView from '../home/view/contact/Activity_view.vue'; 
    import MapView from '../home/view/contact/Map_view.vue'; 
    // components form
    import QrcodeView from '@/components/form/Qrcode_view.vue'; 
    // Options
    import { Options, Vue } from "vue-class-component";
    // Options
    @Options({
      data() {
        return {};
      },
      // mounted() {
      //   // seoTitle
      //   var seoTitle = 'ผู้ลงทุน ขายฝาก รับซื้อ จำนอง-ขายฝาก หานายทุน';
      //   if (seoTitle) {
      //     document.title = seoTitle;
      //   }
      //   // seo meta
      //   var seoDataMeta = '';
      //   var seoDataMeta = [
      //     {
      //       'tag': 'meta',
      //       'name': 'description',
      //       'content': 'ผู้ลงทุน ลงทุนจำนอง – ขายฝากอสังหา สมัคร รับขายฝาก เอง กลุ่ม นายทุน ซื้อ ที่ดิน บ้าน คอนโด ติดต่อนายทุน นายทุนรับซื้อที่ดินต่างจังหวัด ทั่วประเทศ',
      //     },
      //     {
      //       'tag': 'meta',
      //       'name': 'keywords',
      //       'content': 'keywords, ****, ****',
      //     },
      //     {
      //       'tag': 'link',
      //       'name': 'canonical',
      //       'content': (window.location.href) ? window.location.href : '',
      //     },
      //   ];
      //   seoDataMeta.forEach(mySeoMeta);
      //   function mySeoMeta(item, index) {
      //     const metaGet = document.createElement(item.tag);
      //     if (item.tag == 'meta') {
      //       document.querySelector(item.tag + '[name="'+ item.name +'"]').setAttribute("content", item.content);
      //     }
      //     if (item.tag == 'link') {
      //       document.querySelector(item.tag + '[rel="'+ item.name +'"]').setAttribute("href", item.content);
      //     }
      //   }
      // },
      components: {
          FullPageLoader,
          HeaderPageOne,
          // MobileMenuPageOne,
          FooterPageOne,
          ModalPageOne,
          ModalPageTwo,
          // view ----
          InfoView,
          ProductGroupView,
          ProductView,
          SupportView,
          ActivityView,
          MapView,
          // 
          QrcodeView,
      },
      methods: {},
      created() {},
    })
    export default class Home_view extends Vue {}
  </script>

  