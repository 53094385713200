<template>
    <footer class="footer .bg-dark dark-mode position-relative py-5">
        <div class="position-absolute top-0 start-0 w-100 h-100" style="background-color: rgba(255, 255, 255, 0.02)"></div>
        <div class="container position-relative zindex-2 pb-xl-2 pt-2 pt-sm-3 pt-xl-4 pt-xxl-5">
            <div class="row pb-5 pt-md-3 pt-lg-4 mb-md-3 mb-lg-4">
                <div class="col-md-6 col-xxl-5 pb-3 pb-md-0 mb-2 mb-sm-3 mb-md-0">
                    <router-link class="" to="/contact">
                      <a>
                        <img src="/layouts/templates/roofing_info/assets/uploads/logo/logo.png" width="80" alt="">
                      </a>
                    </router-link>
                    <div class="h3 pb-3 mt-3">
                        เครื่องดื่มสมุนไพรชนิดนี้คือ น้ำกระท่อม ดื่มเป็นน้ำสมุนไพรบำรุงร่างกาย
                        <span style="
                                background: linear-gradient(
                                    90.72deg,
                                    #cbfdb1 3.49%,
                                    #acbff1 50.67%,
                                    #efa7ec 100.79%
                                );
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            ">น้ำกระท่อม</span>
                         เบาเบา
                    </div>
                </div>
                <div class="col-md-5 col-xl-4 offset-md-1 offset-xl-2 offset-xxl-3">
                    <div class="row row-cols-2">
                        <div class="col">
                            <ul class="nav flex-column pb-4 mb-2 pb-md-5 mb-lg-1">
                                <li class="nav-item mt-1">
                                    <router-link class="" to="/">
                                      <a class="nav-link py-1 px-0">หน้าแรก</a>
                                    </router-link>
                                </li>
                                <li class="nav-item mt-1">
                                    <router-link class="" to="/show">
                                      <a class="nav-link py-1 px-0">สินค้าของเรา</a>
                                    </router-link>
                                </li>
                                <li class="nav-item mt-1">
                                    <router-link class="" to="/contact">
                                      <a class="nav-link py-1 px-0">ติดต่อเรา</a>
                                    </router-link>
                                </li>
                                <li class="nav-item mt-1">
                                    <a class="nav-link py-1 px-0" :href="envFacebookURL">Facebook {{ envFacebookName }}</a>
                                </li>
                            </ul>
                            <div class="d-flex">
                                <a class="btn btn-icon btn-sm btn-secondary btn-instagram rounded-circle me-3" href="#">
                                  <i class="ai-instagram"></i>
                                </a>
                                <a class="btn btn-icon btn-sm btn-secondary btn-facebook rounded-circle me-3" href="#">
                                  <i class="ai-facebook"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col">
                            <ul class="nav flex-column">
                                <li class="nav-item mt-1">
                                    <a class="nav-link py-1 px-0" :href="envMail">{{envMail}}</a>
                                </li>
                                <li class="nav-item mt-1">
                                    <a class="nav-link py-1 px-0" :href="'tel:' + envTelOne">{{ envTelOne }}</a>
                                </li>
                                <li class="nav-item mt-1">
                                    <a class="nav-link py-1 px-0" :href="envLineURL">Line ID: {{ envLineID }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Copyright-->
            <p class="fs-sm order-md-1 mb-0" ><a class="nav-link d-inline-block .text-muted fs-sm text-decoration-none order-md-2 py-1 px-0 mb-3 mb-md-0" href="https://www.naelike.com" ><span class="opacity-70" >©2023 Baobao เบาเบา V100 </span><b ></b></a><b ><a class="nav-link d-inline fw-normal p-0 ms-2" href="https://fastwork.co/user/somkhane/web-development-48312694" target="_blank" rel="noopener" >Development Fastwork Naelike </a></b></p>
        </div>
    </footer>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
  data() {
    return {
      envMapText: process.env.VUE_APP_CT_MAP_TEXT,
      envMail: process.env.VUE_APP_CT_MAIL,
      envTelOne: process.env.VUE_APP_CT_TEL_ONE,
      envTelTwo: process.env.VUE_APP_CT_TEL_TWO,
      envTelThee: process.env.VUE_APP_CT_TEL_THEE,
      //
      envLineName: process.env.VUE_APP_ST_LINE_NAME,
      envLineID: process.env.VUE_APP_ST_LINE_ID,
      envLineURL: process.env.VUE_APP_ST_LINE_URL,
      //
      envFacebookName: process.env.VUE_APP_ST_FACEBOOK_NAME,
      envFacebookURL: process.env.VUE_APP_ST_FACEBOOK_URL,
    }
  },
  // created
  created() {
    // into
    function into() {
      // console.log(process.env.VUE_APP_MAP_GOOGLE);
      
    }
    setTimeout(function () {
      into();
    }, 400);
  },
})
export default class FooterPageOne extends Vue { }
</script>

<style scoped lang="scss">
  footer {
    background-color: #f6931e !important;
  }

  footer {
    color: #fff;
  }

  footer a {
    color: #fff;
  }
</style>
