<template>
    <div class="page-loading active">
      <div class="page-loading-inner">
        <div class="page-spinner"></div><span>Loading...</span>
      </div>
    </div>
</template>

<script>
  import { Options, Vue } from 'vue-class-component';
  @Options({
    // created
    created() {
        // pageLoading
        function pageLoading() {
          // preloader
          const preloader = document.querySelector('.page-loading');
          if (preloader) {
            preloader.classList.remove('active');
            setTimeout(function () {
              preloader.remove();
            }, 1500);
          }
        }
        // into
        function into() {
          pageLoading();
        }
        setTimeout(function () {
          into();
        }, 400);
      },
  })
  export default class FullPageLoader extends Vue { }
</script>

<style scoped lang="scss"></style>
