<template>
    <section class="pt-5 pb-5">
        <div class="container text-center">
            <img class="mb-5 rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/support/support.png" alt="">
            <h2 class="mb-4">เบาเบา สนับสนุนทุกวงการ</h2>
            <div class="row ">
                <div class="col-md-3 col-6 mb-md-4 mb-4">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/support/support-info-1.png" alt="">
                </div>
                <div class="col-md-3 col-6 mb-md-4 mb-4">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/support/support-info-1.png" alt="">
                </div>
                <div class="col-md-3 col-6 mb-md-4 mb-4">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/support/support-info-1.png" alt="">
                </div>
                <div class="col-md-3 col-6 mb-md-4 mb-4">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/support/support-info-1.png" alt="">
                </div>
                <div class="col-md-3 col-6 mb-md-4 mb-4">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/support/support-info-1.png" alt="">
                </div>
                <div class="col-md-3 col-6 mb-md-4 mb-4">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/support/support-info-1.png" alt="">
                </div>
                <div class="col-md-3 col-6 mb-md-4 mb-4">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/support/support-info-1.png" alt="">
                </div>
                <div class="col-md-3 col-6 mb-md-4 mb-4">
                    <img class="rounded" src="/layouts/templates/roofing_info/assets/uploads/page/home/support/support-info-1.png" alt="">
                </div>
            </div>
        </div>
    </section>
</template>
 
<script lang="js">
    // Options
    import { Options, Vue } from "vue-class-component";
    // Options
    @Options({
        data() {
            return {};
        },
    })
    export default class Home_view extends Vue { }
</script>

<style>
    
</style>
 
